const Fonts = {
	Written: {
		Print: `font-family: "Permanent Marker", cursive;`,
		Cursive: `font-family: "Homemade Apple", system-ui;`,
	},
	Monospace: `font-family: "VT323", monospace;`,
	Encoded: `font-family: "Libre Barcode 39 Text", system-ui;`,
};

export default Fonts;
