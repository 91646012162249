const TitleData = [
	"Tech-Savvy Visionary",
	"Digital Innovator",
	"Web Developer",
	"Software Engineer",
	"Beloved Aunt",
	"Supportive Sister",
	"Cherished Daughter",
	"Proud Granddaughter",
	"Eternal Student",
	"Life Long Learner",
	"Problem Solver",
	"Strategic Debugger",
	"Most Amazing Bestie",
];

export default TitleData;
