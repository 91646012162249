import styled from "styled-components";

// Wrapper for the entire app
export const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: var(--app-height, 100vh);
	transition: all 0.5s ease;
	margin-left: 2.5%;
`;
