import { View } from "../assets/style";
import Quotes from "./Quotes";

const views = [
	<View key="1" className="view">
		<Quotes />
	</View>,
	// <View key="2" className="view">
	// 	<h1>More on the way</h1>
	// </View>,
	// <View key="3" className="view">
	// 	View 3 Content
	// </View>,
	// Add more views as needed
];

export default views;
